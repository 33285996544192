import axios from "axios";
import _map from "lodash/map";

const state = {
  typeOptions: [],
  packageDealOptions: [],
  destinationOptions: [],
  regionOptions: [],
  placeOptions: []
};

const actions = {
  async fetchSearchData({ commit }) {
    const response = await axios.get("/form");
    commit("setTypeOptions", response.data);

    return response;
  },

  async fetchPackageDealOptions({ commit }, value) {
    const response = await axios.get(`/form/aranzman/get?Tip=${value}`)
    commit("setPackageDealOptions", response.data)

    return response
  },

  async fetchDestinationOptions({ commit }, { value, type }) {
    const response = await axios.get(`/form/drzava/get?Aranzman=${value}&Tip=${type}`)
    commit("setDestinationOptions", response.data)

    return response
  },

  async fetchRegionOptions({ commit }, {destination, date, daysAdjustment}) {
    const response = await axios.get(`/form/regions/get?Drzava=${destination}&Datum_polaska=${date}&Opseg=${daysAdjustment}`)
    commit("setRegionOptions", response.data)

    return response
  },

  async fetchPlaceOptions({ commit }, {region, date, daysAdjustment}) {
    const response = await axios.get(`/form/places/get?Regija=${region}&Datum_polaska=${date}&Opseg=${daysAdjustment}`);
    commit("setPlaceOptions", response.data)

    return response
  }
};

const mutations = {
  setTypeOptions(state, searchData) {
    state.typeOptions = _map(searchData[0], value => value.Tip_putovanja_02);
  },
  setPackageDealOptions(state, data) {
    state.packageDealOptions = _map(data, value => value.Tip_putovanja_01)
  },

  setDestinationOptions(state, data) {
    state.destinationOptions = _map(data, value => value.Drzava)
  },

  setRegionOptions(state, data) {
    state.regionOptions = _map(data, value => value.Regija)
  },

  setPlaceOptions(state, data) {
    state.placeOptions = _map(data, value => value.Mesto)
  }
};

const getters = {
  getTypeOptions: (state) => state.typeOptions,
  getPackageDealOptions: (state) => state.packageDealOptions,
  getDestinationOptions: (state) => state.destinationOptions,
  getRegionOptions: (state) => state.regionOptions,
  getPlaceOptions: (state) => state.placeOptions,
};

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
};
