import Model from "@/Models/Model";

export default class Agency extends Model {
  /**
   * Resource
   *
   * @returns {string}
   */
  resource() {
    return "suppliers";
  }
}
