const encodeUri = (value) => {
  return value ? encodeURIComponent(value) : value
}

const prepareAdditionalFilters = (data) => {
  return {
    Noc: data.Noc,
    Soba: data.Soba,
    Hotel: data.Hotel,
    Usluga: data.Usluga,
  }
}

export {
  encodeUri,
  prepareAdditionalFilters
}
