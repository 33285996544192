import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

import "@/assets/scss/main.scss";

// 3rd party plugins
import i18n from "@/i18n";
import vuetify from "@/plugins/vuetify";
import "@/plugins/moment";
import "@/plugins/axios";
import "@/plugins/vue-api-query";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
