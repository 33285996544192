import Vue from "vue";
import Vuex from "vuex";
import filters from "@/store/filters";
import searchData from "@/store/searchData";

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    filters,
    searchData,
  },
});
