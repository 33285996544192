import Agency from "@/Models/Agency";
import { encodeUri } from "@/helpers/utils";
import _uniq from "lodash/uniq";
import _map from "lodash/map";
import _orderBy from "lodash/orderBy";

const getDefaultState = () => ({
  loading: false,
  data: {
    current_page: 1,
    last_page: 0,
    data: [],
  },
  filters: {
    Tip_Putovanja_02: "",
    Tip_Putovanja_01: "",
    Drzava: "",
    Regija: "",
    Mesto: "",
    datum_filter: "",
    PAX_ADL: 1,
    PAX_CHD: 0,
    child_one: 1,
    child_two: 1,
    child_three: 1,
    Noc: "",
    Hotel: "",
    Soba: "",
    Usluga: "",
  },
  nightsOptions: [],
  roomsOptions:[],
  hotelsOptions: [],
  servicesOptions: [],
  additionalFiltersData: [],
  Opseg: 5,
})

const state = getDefaultState()

const actions = {
  async fetchData({ commit }, { page, additionalFilters = false }) {
    try {
      commit("setLoading", true);

      const agency = Agency.custom("/form/filters/get")
        .params({
          page,
          Opseg: state.Opseg,
        })
        .where("Tip_Putovanja_02", encodeUri(state.filters.Tip_Putovanja_02))
        .where("Tip_Putovanja_01", encodeUri(state.filters.Tip_Putovanja_01))
        .where("Drzava", encodeUri(state.filters.Drzava))
        .where("Regija", encodeUri(state.filters.Regija))
        .where("Mesto", encodeUri(state.filters.Mesto))
        .where("datum_filter", encodeUri(state.filters.datum_filter))
        .where("PAX_ADL", encodeUri(state.filters.PAX_ADL));

      if (additionalFilters) {
        agency
          .where("Noc", Number(state.filters.Noc) ? state.filters.Noc : "")
          .where("Hotel",  state.filters.Hotel ? encodeUri(state.filters.Hotel) : "")
          .where("Soba", state.filters.Soba ? encodeUri(state.filters.Soba) : "")
          .where("Usluga", state.filters.Usluga ? encodeUri(state.filters.Usluga) : "");
      }

      const PAX_CHD = Number(state.filters.PAX_CHD);

      if (PAX_CHD) {
        agency
          .where("PAX_CHD", PAX_CHD)
          .where("child_one", encodeUri(state.filters.child_one));

        if (PAX_CHD > 1 || PAX_CHD >= 2) {
          agency.where("child_two", encodeUri(state.filters.child_two));
        }

        if (PAX_CHD >= 3) {
          agency.where("child_three", encodeUri(state.filters.child_three));
        }
      }

      if (!PAX_CHD) {
        agency.where("PAX_CHD", PAX_CHD)
      }

      const response = await agency.get();

      commit("setData", response)
      commit("setLoading", false);

      return response;
    } catch (error) {
      commit("setLoading", false);
    }
  },

  async fetchDataForAdditionalFilters(_, {applyFilters = false, filters}) {
    try {
      const agency = Agency.custom("/form/filters/get")
        .params({
          dodatna: 1,
          Opseg: state.Opseg,
        })
        .where("Tip_Putovanja_02", encodeUri(state.filters.Tip_Putovanja_02))
        .where("Tip_Putovanja_01", encodeUri(state.filters.Tip_Putovanja_01))
        .where("Drzava", encodeUri(state.filters.Drzava))
        .where("Regija", encodeUri(state.filters.Regija))
        .where("Mesto", encodeUri(state.filters.Mesto))
        .where("datum_filter", encodeUri(state.filters.datum_filter))
        .where("PAX_ADL", encodeUri(state.filters.PAX_ADL));

      if (applyFilters) {
        agency
          .where("Noc", filters.Noc ? encodeUri(filters.Noc) : "")
          .where("Hotel", filters.Hotel ? encodeUri(filters.Hotel) : "")
          .where(
            "Soba",
            filters.Soba ? encodeUri(filters.Soba) : ""
          )
          .where("Usluga", encodeUri(filters.Usluga));
      }

      const PAX_CHD = Number(state.filters.PAX_CHD);

      if (PAX_CHD) {
        agency
          .where("PAX_CHD", PAX_CHD)
          .where("child_one", state.filters.child_one);

        if (PAX_CHD > 1 || PAX_CHD >= 2) {
          agency.where("child_two", state.filters.child_two);
        }

        if (PAX_CHD >= 3) {
          agency.where("child_three", state.filters.child_three);
        }
      }

      if (!PAX_CHD) {
        agency.where("PAX_CHD", PAX_CHD)
      }

      return await agency.get();

    } catch (error) {
      return error
    }
  }
};

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setData(state, data) {
    state.data = data;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setRange(state, range) {
    state.Opseg = range;
  },
  setFilterPlace(state, place) {
    state.filters.Mesto = place;
  },
  setCurrentPage(state, page) {
    state.data.current_page = page;
  },

  setNights(state, data) {
    let nights = _uniq(_map(data, (item) => Number(item.Noc))).filter(item => item !== 0)

    state.nightsOptions = _orderBy(nights)
  },

  setRooms(state, data) {
    let rooms = _uniq(
      _map(data, (item) => {
        return {
          name: item.Soba,
          hotel: item.Hotel,
        };
      })
    )

    state.roomsOptions = _orderBy(rooms, [room => room.name.toLowerCase()], ['asc'])
  },

  setHotels(state, data) {
    let hotels = _uniq(
      _map(data, (item) => {
        return {
          name: item.Hotel,
          nights: item.Noc,
        };
      })
    )

    state.hotelsOptions = _orderBy(hotels, [hotel => hotel.name.toLowerCase()], ['asc'])
  },

  setServices(state, data) {
    let servies = _uniq(
      _map(data, (item) => {
        return {
          name: item.Usluga,
          room: item.Soba,
        };
      })
    )

    state.servicesOptions = _orderBy(servies, [service => service.name.toLowerCase()], ['asc'])
  },

  resetData(state) {
    state.data = {
      current_page: 1,
      last_page: 0,
      data: [],
    };
  },
};

const getters = {
  getItems: (state) => state.data,
  getFilters: (state) => state.filters,
  getRange: (state) => Number(state.Opseg),
  getNightsOptions: (state) => state.nightsOptions,
  getRoomsOptions: (state) => state.roomsOptions,
  getHotelsOptions: (state) => state.hotelsOptions,
  getServicesOptions: (state) => state.servicesOptions,
  getLoading: (state) => state.loading,
  getCurrentPage: (state) => state.data.current_page,
  getLastPage: (state) => state.data.last_page,
};
export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
};
