import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import en from 'vuetify/lib/locale/en'
import sr_latin from 'vuetify/lib/locale/sr-Latn'
Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { sr_latin, en },
    defaultLocale: process.env.VUE_APP_I18N_LOCALE,
    current: process.env.VUE_APP_I18N_LOCALE,
  },
  theme: {
    themes: {
      light: {
        primary: "#D60C0C",
        background: "#f5f5f5",
      },
      dark: {},
    },
  },
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
    scrollBarWidth: 16,
    mobileBreakpoint: 600,
  },
});
